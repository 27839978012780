import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../styles/theme';

const TitleStyles = css<{ isBlack?: boolean; withoutPadding?: boolean; alignLeft?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.h4};
    line-height: ${rem(40)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    padding: ${(prop) => (prop.withoutPadding ? 0 : `${rem(20)} 0`)};
    ${(prop) => (prop.isBlack ? `color: ${prop.theme.colors.black}` : `color: ${prop.theme.colors.primary}`)};

    @media (min-width: ${breakpoints.sm}px) {
        ${(prop) => (prop.alignLeft ? 'text-align: left' : 'text-align: center')};
        padding: ${(prop) => (prop.withoutPadding ? 0 : `${rem(20)} 0 ${rem(45)} 0`)};
        font-size: ${({ theme }) => theme.fontSizes.h3};
    }
`;

export const WrapperDiv = styled.div`
    ${TitleStyles}
`;

export const WrapperH1 = styled.h1`
    ${TitleStyles}
`;

export const WrapperH2 = styled.h2`
    ${TitleStyles}
`;

export const WrapperH3 = styled.h3`
    ${TitleStyles}
`;
