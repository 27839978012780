import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Background = styled.div`
    background: rgba(116, 120, 129, 0.08);
    padding: ${rem(30)};

    @media (min-width: ${breakpoints.md}px) {
        padding: ${rem(80)} 0;
    }
`;

export const Wrapper = styled.div`
    @media (min-width: ${breakpoints.md}px) {
        display: flex;
        flex-direction: row;
    }
`;

export const Title = styled.h2`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    font-size: ${({ theme }) => theme.fontSizes.h3};
`;

export const Description = styled.p`
    line-height: ${rem(29)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    margin: ${rem(20)} 0 ${rem(15)} 0;
    font-size: ${({ theme }) => theme.fontSizes.h6};

    @media (min-width: ${breakpoints.md}px) {
        margin: ${rem(25)} 0 ${rem(40)} 0;
    }
`;

export const Benefits = styled.ul`
    color: ${({ theme }) => theme.colors.grey};
    padding: 0 ${rem(20)};
    font-size: ${({ theme }) => theme.fontSizes.h6};
`;

export const Benefit = styled.li`
    padding: ${rem(15)} 0 ${rem(15)} ${rem(10)};

    &::marker {
        color: ${({ theme }) => theme.colors.black};
        width: ${rem(4)};
        height: ${rem(4)};
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;

    @media (min-width: ${breakpoints.md}px) {
        margin-left: 5%;
        width: 40%;
        max-height: ${rem(500)};
    }
`;
