import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import Slider from 'react-slick';
import { useRouter } from 'next/router';
import useTranslations from '@hooks/useTranslation';
import Title from '@ui/SectionTitle/Title';
import { formatLecturerName } from '@utils/person.utils';
import { publicAssets } from '@utils/url.utils';
import { ROUTES, getImageBaseURL } from '../../constants';
import { ILecturerItem } from '../../interfaces/Lecturer';
import { lecturerApi } from '../../services/lecturer.service';
import ContentTemplate from '../../ui/Content/Content';
import { Exhibitor, ExhibitorAnnotation, ExhibitorImage, ExhibitorTitle, Exhibitors } from './Exhibitors.elements';

export interface ExhibitorsProps {}

const ExhibitorsTemplate: FC<ExhibitorsProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    const [lecturers, setLecturers] = useState([]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: false,
        responsive: [
            {
                breakpoint: 320,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false },
            },
            {
                breakpoint: 1024,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false },
            },
        ],
    };

    useEffect(() => {
        lecturerApi.list().then(({ data }) => {
            setLecturers(data.results);
        });
    }, []);

    return (
        lecturers?.length > 0 && (
            <ContentTemplate withPadding mobileSidePadding={20}>
                <Title isBlack>{t('homepage.lecturer.title')}</Title>
                <Exhibitors>
                    <Slider {...settings}>
                        {lecturers
                            ?.sort((a, b) => a.in_carousel - b.in_carousel)
                            ?.map((lecturer: ILecturerItem, key: number) => (
                                <Exhibitor key={key} onClick={() => router.push(`${ROUTES.LECTURER}/${lecturer.slug}`)}>
                                    <ExhibitorImage
                                        src={
                                            lecturer.photo
                                                ? `${getImageBaseURL()}/media/people/${lecturer._key}`
                                                : `${publicAssets('/avatar_height.jpg')}`
                                        }
                                        width={0}
                                        height={0}
                                        alt={`${getImageBaseURL()}/media/people/${lecturer._key}`}
                                        loader={({ src }: any) => src}
                                    />
                                    <ExhibitorTitle>{formatLecturerName(lecturer)}</ExhibitorTitle>
                                    {lecturer?.position && (
                                        <ExhibitorAnnotation>{lecturer.position}</ExhibitorAnnotation>
                                    )}
                                </Exhibitor>
                            ))}
                    </Slider>
                </Exhibitors>
            </ContentTemplate>
        )
    );
};

export default ExhibitorsTemplate;
