import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import CalendarTemplate from '@components/Calendar/Calendar';
import ClientsTemplate from '@components/Clients/Clients';
import CompanyCoursesTemplate from '@components/CompanyCourses/CompanyCourses';
import FooterTemplate from '@components/Footer/Footer';
import HeaderTemplate from '@components/Header/Header';
import HeroTemplate from '@components/HomepageHero/Hero';
import MyAccountTemplate from '@components/MyAccountHome/MyAccount';
import useTranslation from '@hooks/useTranslation';
import DividerTemplate from '@ui/Divider/Divider';
import moment from 'moment/moment';
import Exhibitors from '../components/Exhibitors/Exhibitors';
import { ACCESS_TOKEN_KEY, IS_PROD } from '../constants';
import { ITrainingItem } from '../interfaces/Calendar';
import { calendarApi } from '../services/calendar.service';
import { RootState } from '../store';
import ContentTemplate from '../ui/Content/Content';

interface IFrontPage {
    events: ITrainingItem[];
    eventsCount: number;
}

const FrontPage: NextPage<IFrontPage> = ({ events, eventsCount }) => {
    const { t } = useTranslation();

    const isLoggedInState = useSelector((state: RootState) => state.ui.isLoggedIn);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        setIsLoggedIn(isLoggedInState);
    }, [isLoggedInState]);

    return (
        <>
            <NextSeo title={t('seo.homepage.title')} description="" noindex={!IS_PROD} nofollow={!IS_PROD} />
            <HeaderTemplate />
            {isLoggedIn ? (
                <ContentTemplate withPadding>
                    <MyAccountTemplate />
                </ContentTemplate>
            ) : (
                <HeroTemplate />
            )}
            <DividerTemplate />
            <ContentTemplate mobileSidePadding={20}>
                {eventsCount > 0 && <CalendarTemplate events={events} count={eventsCount} />}
            </ContentTemplate>
            <CompanyCoursesTemplate />
            <Exhibitors />
            <ClientsTemplate titleKey={'homepage.clients.title'} showClients />
            <FooterTemplate />
        </>
    );
};

export async function getServerSideProps(ctx: any) {
    const token = ctx?.res?.req?.cookies?.[ACCESS_TOKEN_KEY];

    try {
        const { data: events } = await calendarApi.getUpComingEvents(moment().format('YYYY-MM-DDT00:00:00'), token);

        return {
            props: {
                events: events?.results,
                eventsCount: events?.total_count,
            },
        };
    } catch {
        return {
            props: {
                events: [],
                eventsCount: 0,
            },
        };
    }
}

export default FrontPage;
