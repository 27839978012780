import React, { FC } from 'react';
import useScrollToSection from '@hooks/useScrollToSection';
import useTranslation from '@hooks/useTranslation';
import TitleTemplate, { TitleTagTypes } from '@ui/SectionTitle/Title';
import { publicAssets } from '@utils/url.utils';
import ContentWrapper from '../../ui/Content/Content';
import { Client, ClientImage, Clients, Wrapper } from './Clients.elements';

interface ClientsProps {
    titleKey: string;
    showClients?: boolean;
    showPartners?: boolean;
}

const ClientsTemplate: FC<ClientsProps> = ({ titleKey, showClients, showPartners }) => {
    const { t } = useTranslation();

    useScrollToSection('nasi-partneri', 'partneri');

    const clients = [
        '/italians_logo.png',
        '/valeo_logo.png',
        '/keramtech_logo.png',
        '/safran_logo.png',
        '/hyundai_logo.png',
        '/nexen_logo.png',
        '/danfos_logo.png',
    ];

    const partners = ['/skoleni_logo.png', '/grada_logo.png', '/sagit_logo.png', '/educity_logo.png'];

    return (
        <ContentWrapper id="partneri" mobileSidePadding={20}>
            <Wrapper>
                <TitleTemplate tagType={TitleTagTypes.H3} isBlack>
                    {t(titleKey)}
                </TitleTemplate>
                {showClients && (
                    <Clients>
                        {clients.map((client, index) => (
                            <Client key={index}>
                                <ClientImage src={publicAssets(client)} />
                            </Client>
                        ))}
                    </Clients>
                )}
                {showPartners && (
                    <Clients>
                        {partners.map((partner, index) => (
                            <Client key={index}>
                                <ClientImage src={publicAssets(partner)} />
                            </Client>
                        ))}
                    </Clients>
                )}
            </Wrapper>
        </ContentWrapper>
    );
};
export default ClientsTemplate;
