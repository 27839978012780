import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding: ${rem(80)} 0 ${rem(20)} 0;

    @media (min-width: ${breakpoints.sm}px) {
        padding: ${rem(60)} 0 ${rem(50)} 0;
    }
`;

export const Clients = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

export const Client = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${rem(20)};
    padding-bottom: ${rem(40)};
`;

export const ClientImage = styled.img`
    width: ${rem(130)};
    margin: 0 auto;
`;
