import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Icons } from '@components/Icon';
import { IconTemplate } from '@components/Icon/IconTemplate';
import useTranslations from '@hooks/useTranslation';
import { ROUTES } from '../../constants';
import { Section, Wrapper } from './MyAccount.elements';

export interface MyAccountProps {}

const MyAccountTemplate: FC<MyAccountProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    return (
        <Wrapper>
            <Section onClick={() => router.push({ pathname: ROUTES.MY_PV_COURSES })}>
                <IconTemplate name={Icons.MyCourses} />
                <span dangerouslySetInnerHTML={{ __html: t('header.myCourses') }} />
            </Section>
            <Section onClick={() => router.push({ pathname: ROUTES.MY_PV_CONFERENCES })}>
                <IconTemplate name={Icons.MyConferences} />
                <span dangerouslySetInnerHTML={{ __html: t('header.myConferences') }} />
            </Section>
            <Section onClick={() => router.push({ pathname: ROUTES.MY_PV_FAVOURITES })}>
                <IconTemplate name={Icons.MyFavourites} />
                <span dangerouslySetInnerHTML={{ __html: t('header.myFavourites') }} />
            </Section>
            <Section onClick={() => router.push({ pathname: ROUTES.MY_PV_MATERIALS })}>
                <IconTemplate name={Icons.MyMaterials} />
                <span dangerouslySetInnerHTML={{ __html: t('header.myMaterials') }} />
            </Section>
        </Wrapper>
    );
};

export default MyAccountTemplate;
