import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div<{ marginTop: number; marginBottom: number }>`
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.colors.border};
    ${(prop) => (prop.marginTop ? `margin-top: ${rem(prop.marginTop)}` : '')};
    ${(prop) => (prop.marginBottom ? `margin-bottom: ${rem(prop.marginBottom)}` : '')};
`;
