import React, { FC } from 'react';
import { useRouter } from 'next/router';
import useTranslation from '@hooks/useTranslation';
import ButtonTemplate, { ButtonSizes } from '@ui/Button/Button';
import { publicAssets } from '@utils/url.utils';
import { ROUTES } from '../../constants';
import ContentWrapper from '../../ui/Content/Content';
import { Background, Benefit, Benefits, Description, Image, Title, Wrapper } from './CompanyCourses.elements';

interface CompanyCoursesProps {}

const CompanyCoursesTemplate: FC<CompanyCoursesProps> = () => {
    const { t } = useTranslation();

    const router = useRouter();

    return (
        <Background>
            <ContentWrapper>
                <Wrapper>
                    <div>
                        <Title>{t('homepage.companyCourses.title')}</Title>
                        <Description>{t('homepage.companyCourses.description')}</Description>
                        <Benefits>
                            <Benefit>{t('homepage.companyCourses.benefit1')}</Benefit>
                            <Benefit>{t('homepage.companyCourses.benefit2')}</Benefit>
                            <Benefit>{t('homepage.companyCourses.benefit3')}</Benefit>
                            <Benefit>{t('homepage.companyCourses.benefit4')}</Benefit>
                            <Benefit>{t('homepage.companyCourses.benefit5')}</Benefit>
                            <Benefit>{t('homepage.companyCourses.benefit6')}</Benefit>
                        </Benefits>
                        {!router.pathname.includes(ROUTES.COMPANY_COURSES) && (
                            <ButtonTemplate
                                marginTop={15}
                                marginBottom={45}
                                size={ButtonSizes.SMALL}
                                onClick={() => router.push({ pathname: ROUTES.COMPANY_COURSES })}
                                whiteType
                                text={t('homepage.companyCourses.button')}
                            />
                        )}
                    </div>
                    <Image src={publicAssets('/courses.png')} />
                </Wrapper>
            </ContentWrapper>
        </Background>
    );
};
export default CompanyCoursesTemplate;
