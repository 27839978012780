import coreApiService from './core.service';

const LIST = 'person/list/carousel';
const DETAIL = 'pv/person/slug';

export const lecturerApi = {
    list: () => coreApiService.get(LIST),
    detail: (slug: string) => coreApiService.get(`${DETAIL}/${slug}`),
    upcomingTrainings: (slug: string, limit: number, offset: number, excludeEvents?: string[]) =>
        coreApiService.get(`${DETAIL}/${slug}/upcoming/courses`, {
            limit,
            offset,
            excludeEvents,
        }),
    upcomingConferences: (slug: string, limit: number, offset: number) =>
        coreApiService.get(`${DETAIL}/${slug}/upcoming/conferences`, {
            limit,
            offset,
        }),
    records: (slug: string, limit: number, offset: number, excludeEvents?: string[]) =>
        coreApiService.get(`${DETAIL}/${slug}/archived/courses`, { limit, offset, excludeEvents }),
};
