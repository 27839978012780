import React from 'react';
import { FC } from 'react';
import { Wrapper } from './Divider.elements';

export interface DividerProps {
    marginTop?: number;
    marginBottom?: number;
}

const DividerTemplate: FC<DividerProps> = ({ marginTop, marginBottom }) => {
    return <Wrapper marginTop={marginTop} marginBottom={marginBottom} />;
};

export default DividerTemplate;
