import Image from 'next/image';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Exhibitors = styled.div`
    padding: ${rem(20)} 0;
`;

export const Exhibitor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-bottom: ${rem(20)};
    cursor: pointer;
`;

export const ExhibitorTitle = styled.div`
    padding: ${rem(20)} 0 ${rem(10)} 0;
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
`;

export const ExhibitorAnnotation = styled.span`
    color: ${({ theme }) => theme.colors.grey};
`;

export const ExhibitorImage = styled(Image)`
    width: ${rem(155)};
    height: ${rem(180)};
    border-radius: ${rem(16)};

    @media (min-width: ${breakpoints.xs}px) {
        width: ${rem(190)};
        height: ${rem(220)};
    }
`;
